<template>
  <div class="card setting_card">
    <div class="title">User Settings</div>
    <div class="setting_body">
      <div class="form-group d-flex align-items-center ml-10">
        <p>{{}}</p>
        <b-table
          no-border-collapse
          hover
          :items="userdata"
          :fields="fields"
          head-row-class="table-header"
          sort-icon
          class="task-list custom-b-table-class"
        >
          <template #cell(name)="data">
            <div class="text-left">{{ data.item.name }}</div>
          </template>

          <template #cell(email)="data">
            <div class="text-left">{{ data.item.email }}</div>
          </template>

          <template #cell(created_at)="data">
            <div class="text-left">{{ formatDate(data.item.created_at) }}</div>
          </template>

          <template #cell(total_upload)="data">
            <div class="text-center">{{ data.item.total_upload }}</div>
          </template>

          <template #cell(total_processed)="data">
            <div class="text-center">{{ data.item.total_processed }}</div>
          </template>

          <template #cell(total_paid)="data">
            <div class="text-center">{{ data.item.total_paid }}</div>
          </template>

          <template #cell(action)="data">
            <div class="text-center">
              <button @click="deleteuser(data.item.id)">
                <inline-svg src="media/svg/icons/General/trash_icon.svg" />
              </button>
            </div>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
//   import { ADD_COUPON, GET_COUPON, DELETE_COUPON } from "@/core/services/store/tasks.module";
//   import { GET_LIMIT } from "@/core/services/store/auth.module";
import { mapGetters } from "vuex";
// import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      fields: [
        {
          key: "name",
          Label: "User Name",
        },
        {
          key: "email",
          Label: "Email",
        },
        {
          key: "created_at",
          Label: "Created At",
        },
        {
          key: "total_upload",
          Label: "Total Upload",
        },
        {
          key: "total_processed",
          Label: "Total Processed",
          thClass: "text-center",
        },
        {
          key: "total_paid",
          Label: "Total Paid",
          thClass: "text-center",
        },
        {
          key: "action",
          Label: "Action",
          thClass: "text-center",
        },
      ],
      userdata: {},
    };
  },

  mounted() {
    this.getdata();
  },

  methods: {
    formatDate(dateString) {
      const date = new Date(dateString);
      // Then specify how you want your dates to be formatted
      return new Intl.DateTimeFormat("en-GB", {
        month: "2-digit",
        day: "2-digit",
        year: "numeric",
      }).format(date);
      // {dateStyle: 'short'}
    },

    getdata() {
      ApiService.post("userdetails").then((response) => {
        this.userdata = response.data;
      });
    },

    deleteuser(userid) {
      // eslint-disable-next-line no-console
      console.error("deleteuser", userid);
    },
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
};
</script>
<style scoped>
.title {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 30px;
  /* identical to box height */
  color: #3d415b;
  padding: 20px;
}

.setting_card {
  background: #ffffff;
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04);
  border-radius: 16px;
}

.form-group label {
  /* height: px; */
  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 22px;
  /* identical to box height, or 129% */
  text-align: right;
  margin-right: 10px;
  letter-spacing: -0.408px;

  color: #0e0e0f;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.form-group .form-check-input {
  height: 20px;
  width: 20px;
}

/* .form-check label{
      height: 22px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 22px;
  
  text-align: right;
  margin-right: 10px;
  letter-spacing: -0.408px;
  
  color: #0E0E0F;
    } */
.form-control.form-control-solid {
  font-size: 15px;
  font-weight: 400;
  width: 210px;
  height: 50px;
  letter-spacing: -0.408px;
  font-feature-settings: "case" on;
  background: #f4f5f9;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
  border-radius: 8px;
  text-align: left;
  position: relative;
  box-sizing: border-box;
  border: 0;
  color: #6f7392;
}

.setting_body {
  padding: 15px;
}

.task-list {
  border-collapse: separate;
  border-spacing: 0px 10px;
}

.task-list thead th {
  height: 13px;
  font-size: 15px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: 22px;
  letter-spacing: normal;
  text-align: left;
  /* box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04); */
  /* border: solid 1px rgba(171, 164, 164, 0.8); */
  border-bottom: 0;
  border-left: 0;
  font-family: "Poppins";

  position: absolute;
  background-color: #ebebeb;
  /* border-right: 0; */
}

.tableheads::after {
  content: "";
  height: 22px;
  width: 22px;
  border-right: solid 1px rgba(184, 179, 179, 0.8);
  position: absolute !important;
  top: 11px;
  left: -17px;
  border-left: 0;
}

.task-list tbody tr {
  padding: 23px 25px 23px 28px;
  border-radius: 8px;
  /* box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.04); */
  border: solid 1px rgba(96, 94, 94, 0.8);
  /* background-color: white; */
}

.task-list tbody tr td {
  width: 97px;
  height: 23px;
  left: 68px;
  top: 19px;

  font-family: "Poppins";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 22px;

  /* identical to box height */

  /* color: #3D415B; */
  color: #3d415b;
  cursor: pointer;
}
</style>
